import Icon1 from "../../../assets/image/app/6.svg";
import Icon2 from "../../../assets/image/app/1.svg";
import Icon3 from "../../../assets/image/app/2.svg";
import Icon4 from "../../../assets/image/app/3.svg";
import Icon5 from "../../../assets/image/app/4.svg";
import Icon6 from "../../../assets/image/app/5.svg";

const data = {
  features: [
    {
      id: 1,
      image: Icon1,
      title: "Peace of Mind"
    },
    {
      id: 2,
      image: Icon2,
      title: "Efficient"
    },
    {
      id: 3,
      image: Icon3,
      title: "World Class Pros"
    },

    {
      id: 4,
      image: Icon4,
      title: "Swift Turnaround"
    },

    {
      id: 5,
      image: Icon5,
      title: "Insuraned"
    },

    {
      id: 6,
      image: Icon6,
      title: "Fully Managed"
    }
  ]
};
export default data;
