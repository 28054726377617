const data = {
  menuWidget: [
    {
      id: 1,
      title: "About TPP",
      menuItems: [
        {
          id: 1,
          url: "tel:‭+1-310-529-0044‬",
          text: "Click to Call!"
        },
        {
          id: 2,
          url:
            "mailto:info@theprojectphoto.com?subject=TPP Landing Early Access Program",
          text: "Email Us"
        }
      ]
    },
    {
      id: 2,
      title: "Photo Shoot Types",
      menuItems: [
        {
          id: 1,
          url: "#",
          text: "Real Estate"
        },
        {
          id: 2,
          url: "#",
          text: "Event"
        },
        {
          id: 3,
          url: "#",
          text: "Action"
        },
        {
          id: 4,
          url: "#",
          text: "Fashion"
        },
        {
          id: 5,
          url: "#",
          text: "Media"
        },
        {
          id: 6,
          url: "#",
          text: "Food"
        }
      ]
    },
    {
      id: 3,
      title: "Benefits",
      menuItems: [
        {
          id: 1,
          url: "#",
          text: "World Class Quality"
        },
        {
          id: 2,
          url: "#",
          text: "Advanced Technology"
        },
        {
          id: 3,
          url: "#",
          text: "Scalable"
        },
        {
          id: 4,
          url: "#",
          text: "Efficient"
        },
        {
          id: 5,
          url: "#",
          text: "Full Service"
        },
        {
          id: 6,
          url: "#",
          text: "Seamless"
        }
      ]
    }
  ]
};
export default data;
