import React from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Container from "../../../components/UI/Container";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import data from "../../../data/App/TestimonialSlider";
import SliderDes from "../sliderDescription";
import Image1 from "../../../assets/image/app/tpp-icon-film.png";
import Image2 from "../../../assets/image/app/tpp-icon-tripod.png";
import Image3 from "../../../assets/image/app/tpp-icon-lens.png";
import Image4 from "../../../assets/image/app/tpp-icon-camera-roll.png";
import Image5 from "../../../assets/image/app/tpp-icon-drone.png";

const images = [
  {
    thumbnail: `${Image1}`,
    description: <SliderDes data={data.testimonials[0]} />
  },
  {
    thumbnail: `${Image2}`,
    description: <SliderDes data={data.testimonials[1]} />
  },
  {
    thumbnail: `${Image3}`,
    description: <SliderDes data={data.testimonials[2]} />
  },
  {
    thumbnail: `${Image4}`,
    description: <SliderDes data={data.testimonials[3]} />
  },
  {
    thumbnail: `${Image5}`,
    description: <SliderDes data={data.testimonials[4]} />
  }
];

const TestimonialSection = ({ sectionWrapper, row, sectionSubTitle }) => {
  return (
    <Box
      {...sectionWrapper}
      className="testimonialSlider"
      id="testimonialSection"
    >
      <Container>
        <Box className="testimonialDesWrapper">
          <ImageGallery
            items={images}
            originalClass="Testimonial-img"
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </Box>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  title: PropTypes.object
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: "0px",
    pb: ["20px", "80px", "0px", "80px", "80px"]
  },

  sectionSubTitle: {
    content: "CLIENT TESTIMONIAL",
    as: "span",
    display: "block",
    textAlign: ["center", "left"],
    fontSize: "14px",
    letterSpacing: "0.11em",
    fontWeight: "700",
    color: "#1a73e8",
    textTransform: "uppercase",
    mb: "10px"
  }
};

export default TestimonialSection;
