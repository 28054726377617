const data = {
  testimonials: [
    {
      id: 1,
      description:
        "With our network and technology at your fingertips, you will never have to worry about photos again!",
      name: "David Justin",
      designation: "Founder of Dumpy"
    },
    {
      id: 2,
      description:
        "But far the most streamlined service for photos I have ever used.",
      name: "Roman Ul Oman",
      designation: "Co-founder of QatarDiaries"
    },

    {
      id: 3,
      description:
        "All I had to do was ship my product and The Project Photo took care of everything else. I would totally recommend!",
      name: "Caroleane Mina",
      designation: "Director of Beauty-queen"
    },

    {
      id: 4,
      description:
        "The photographers on TPP were great to work with. Very nice and great photo skills.",
      name: "Kyle More",
      designation: "Co-founder of Softo"
    },
    {
      id: 5,
      description:
        "I was in a rush to get photos taken but didn’t have time to do it myself. TPP really came through in a pinch.",
      name: "Keith Berlin",
      designation: "Co-founder of Antinio"
    }
  ]
};
export default data;
