import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import "react-image-gallery/styles/css/image-gallery.css";
import Container from "../../../components/UI/Container";
import FeatureSliderWrapper from "./featureSlider.style";
import Image1 from "../../../assets/image/app/tpp-image07.jpg";
import Image2 from "../../../assets/image/app/tpp-image08.jpg";
import Image3 from "../../../assets/image/app/tpp-image09.jpg";

import Thumb1 from "../../../assets/image/app/app1.svg";
import Thumb2 from "../../../assets/image/app/app2.svg";
import Thumb3 from "../../../assets/image/app/app3.svg";
import Thumb4 from "../../../assets/image/app/app4.svg";
import Thumb5 from "../../../assets/image/app/app5.svg";
import Thumb6 from "../../../assets/image/app/app6.svg";

// import DomainSection from '../container/Hosting/Domain';
const images = [
  {
    original: `${Image1}`,
    thumbnail: `${Thumb1}`,
    thumbnailLabel: "Peace of Mind"
  },
  {
    original: `${Image2}`,
    thumbnail: `${Thumb2}`,
    thumbnailLabel: "Efficient"
  },
  {
    original: `${Image3}`,
    thumbnail: `${Thumb3}`,
    thumbnailLabel: "World Class Pros"
  },
  {
    original: `${Image1}`,
    thumbnail: `${Thumb4}`,
    thumbnailLabel: "Swift Turnaround"
  },
  {
    original: `${Image2}`,
    thumbnail: `${Thumb5}`,
    thumbnailLabel: "Insuraned"
  },
  {
    original: `${Image3}`,
    thumbnail: `${Thumb6}`,
    thumbnailLabel: "Fully Managed"
  }
];

const FeatureSlider = ({ sectionSubTitle, sectionTitle, sectionHeader }) => {
  return (
    <FeatureSliderWrapper id="keyfeature">
      <div className="FeatureSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} />
          <Heading {...sectionTitle} />
        </Box>
        <Box className="FeatureSlider">
          <ImageGallery
            items={images}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay={true}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionHeader: PropTypes.object
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  sectionHeader: {},
  sectionSubTitle: {
    content: "WHY CHOOSE US",
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.13em",
    fontWeight: "700",
    color: "#b11f28",
    mb: "10px"
  },
  // section title default style
  sectionTitle: {
    content: "Why use TPP?",
    textAlign: "center",
    fontSize: ["20px", "24px", "24px", "24px", "30px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0"
  }
};

export default FeatureSlider;
