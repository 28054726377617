const data = {
  features: [
    {
      id: 1,
      icon: require("../../../assets/image/app/tpp-icon-camera.png"),
      title: "Photography",
      description:
        "Give us all the deets (details). Our goal is to turn your vision into reality. You give us examples of what you are looking for and we take care of the rest."
    },
    {
      id: 2,
      icon: require("../../../assets/image/app/tpp-icon-list.png"),
      title: "Send Us <strong>THE PRODUCT</strong>",
      description:
        "After you've given us the details, we will pair you with the best photographer for your project. Input your project dimensions and weight, print a shipping label and send it off to the photographer selected. Once the photographer receives the items that were sent, him/her can successfully get your photos shot and edited in a shortly matter of time. You will have your photos in no time!"
    },
    {
      id: 3,
      icon: require("../../../assets/image/app/tpp-icon-globe.png"),
      title: "Receive Digital Photos",
      description:
        "Within 5 days of shipment, your photos will be delivered digitally via secure TPP Link, ready to share and use by simply signing in and downloading."
    }
  ]
};
export default data;
