import React from "react";
import PropTypes from "prop-types";
// import Link from "next/link";

import addToMailchimp from "gatsby-plugin-mailchimp";

import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Card from "reusecore/src/elements/Card";
import Image from "reusecore/src/elements/Image";
import Input from "reusecore/src/elements/Input";
import Select from "reusecore/src/elements/Select";
import FeatureBlock from "../../../components/FeatureBlock";
import ParticlesComponent from "../particles";
import Container from "../../../components/UI/Container";
import { email } from "react-icons-kit/ionicons/email";
import { Icon } from "react-icons-kit";
import { ic_arrow_forward } from "react-icons-kit/md/ic_arrow_forward";
import { BannerSquareShape, BannerCircleShape } from "../app.style";
import BannerWrapper, {
  DiscountWrapper,
  DiscountLabel,
  ButtonWrapper,
  EmailInputWrapper
} from "./banner.style";

import AppScreenshot from "../../../assets/image/app/tpp-image01-1.png";

class DomainSection extends React.Component {
  state = {
    mailchimpSettings: {},
    email: "",
    group: "", 
    msg: ""
  };

  onFieldEmailChange = e => {
    this.setState({ email: e.target.value});
    this.setState({ msg: '' });
  };

  onFieldGroupChange = e => {
    this.setState({ group: e.value });
    this.setState({ msg: '' });
    
    if ( e.value === 'creator' ) {
      this.setState({
        mailchimpSettings: {
          'group[23565][1]' : '1'
        } 
      });
    } else if ( e.value === 'customer' ) {
      this.setState({
        mailchimpSettings: {
          'group[23565][2]' : '2'
        } 
      });
    } else {

    }
  };

  handleSubscribe = e => {
    e.preventDefault();

    console.log(this.state);

    if ( this.state.group ) {
      addToMailchimp(this.state.email, this.state.mailchimpSettings)
      .then(data => {
        console.log(data.result)
        this.setState({ email: ''});
        this.setState({ msg: data.msg });
      })
      .catch(() => {});
    } else {
      this.setState({ msg: 'Some fields are empty.'});
    }

    
  };

  render() {
    return (
      <div id="home">
      <Box {...this.props.SectionWrapper}>
        <ParticlesComponent />
        <BannerSquareShape />
        <BannerCircleShape />
        <Container>
          <Box {...this.props.row}>
            <Box {...this.props.col}>
              <Box>
                <DiscountWrapper>
                  <DiscountLabel>
                    <Text
                      {...this.props.discountAmount}
                      className="discountAmount"
                    />
                    <Text {...this.props.discountText} />
                  </DiscountLabel>
                </DiscountWrapper>
              </Box>
              <FeatureBlock
                title={<Heading {...this.props.title} />}
                description={<Text {...this.props.description} />}
              />
              <EmailInputWrapper>
                <div className="reusecore__input  inputstyle__InputField-fPgjrW cHkxCs">
                  <div className="field-wrapper">
                    <input
                      style={{ fontSize: "16px", width: "100%" }}
                      placeholder="Enter Email Address For Early Access"
                      type="email"
                      value={this.state.email}
                      onChange={this.onFieldEmailChange}
                    />
                  </div>
                </div>
              </EmailInputWrapper>
              <Select 
              required
              placeholder="Sign up as"
                onChange={this.onFieldGroupChange}
                options={[
                  {
                    label: 'Creator',
                    value: 'creator'
                  },
                  {
                    label: 'Customer',
                    value: 'customer'
                  }
                ]} />

              <ButtonWrapper>
                <Button
                  {...this.props.button}
                  {...this.props.btnStyle}
                  onClick={this.handleSubscribe}
                />
                {/* <Link href="#">
                  <a>
                    <Button
                      {...this.props.button}
                      {...this.props.btnStyleTwo}
                      icon={<Icon icon={ic_arrow_forward} color="#f00" />}
                      className="withoutBg"
                    />
                  </a>
                </Link> */}
              </ButtonWrapper>
              <div
                dangerouslySetInnerHTML={{ __html: this.state.msg }}
                style={{ margin: "15px 0" }}
              />
            </Box>
            <Box {...this.props.col} {...this.props.imageArea}>
              <Image
                src={AppScreenshot}
                alt="Domain Image"
                {...this.props.image}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      </div>
    );
  }
}

// const DomainSection = ({
//   SectionWrapper,
//   row,
//   col,
//   title,
//   description,
//   button,
//   image,
//   imageArea,
//   btnStyle,
//   btnStyleTwo,
//   textArea,
//   discountAmount,
//   discountText
// }) => {
//   return (
//     <Box {...SectionWrapper}>
//       {/* <ParticlesComponent /> */}
//       <BannerSquareShape />
//       <BannerCircleShape />
//       <Container>
//         <Box {...row}>
//           <Box {...col}>
//             <Box>
//               <DiscountWrapper>
//                 <DiscountLabel>
//                   <Text {...discountAmount} className="discountAmount" />
//                   <Text {...discountText} />
//                 </DiscountLabel>
//               </DiscountWrapper>
//             </Box>
//             <FeatureBlock
//               title={<Heading {...title} />}
//               description={<Text {...description} />}
//             />
//             <EmailInputWrapper>
//               <Input
//                 inputType="email"
//                 placeholder="Enter Email Address For Early Access"
//                 iconPosition="left"
//               />
//             </EmailInputWrapper>
//             <ButtonWrapper>
//               <Link href="#services">
//                 <a>
//                   <Button {...button} {...btnStyle} />
//                 </a>
//               </Link>
//               <Link href="#">
//                 <a>
//                   <Button
//                     {...button}
//                     {...btnStyleTwo}
//                     icon={<Icon icon={ic_arrow_forward} color="#f00" />}
//                     className="withoutBg"
//                   />
//                 </a>
//               </Link>
//             </ButtonWrapper>
//           </Box>
//           <Box {...col} {...imageArea}>
//             <Image src={AppScreenshot} alt="Domain Image" {...image} />
//           </Box>
//         </Box>
//       </Container>
//     </Box>
//   );
// };

DomainSection.propTypes = {
  SectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyle: PropTypes.object,
  btnStyleTwo: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  textArea: PropTypes.object
};

DomainSection.defaultProps = {
  SectionWrapper: {
    as: "section",
    pt: "80px",
    pb: "80px",
    overflow: "hidden"
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center"
  },
  imageAreaRow: {
    flexDirection: "row-reverse"
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "100%", "50%", "44%", "44%"],
    mt: "-80px"
  },
  // textArea: {
  // 	width: [1, '42%'],
  // },
  imageArea: {
    width: ["0%", "0%", "43%", "35%", "50%"],
    ml: "auto"
  },
  title: {
    content:
      "Photography made easy. Images that sell. Convenience that's addictive.",
    fontSize: ["26px", "30px", "30px", "48px", "60px"],
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.01px",
    mb: "20px"
  },
  description: {
    content:
      "Our TPP vetted world-class photographers create beautiful content focused on product photography to help brands drive revenue.",
    fontSize: "16px",
    color: "#343d48",
    lineHeight: "33px",
    mb: "10px"
  },
  button: {
    title: "SIGN ME UP",
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg"
  },
  image: {
    ml: "auto",
    mt: "70px"
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500"
  },
  btnStyleTwo: {
    title: "WATCH DEMOS",
    type: "button",
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    ml: "15px",
    bg: "#fff",
    color: "#000"
  },
  textArea: {
    width: ["100%", "100%", "50%", "55%", "55%"]
  },
  discountAmount: {
    content: "GET ACCESS",
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    mb: 0,
    as: "span",
    mr: "0.4em",
    bg: "#b11f28",
    display: "inline-block",
    flex: "none"
  },
  discountText: {
    content: "TPP is accepting early applicants into the beta platform.",
    fontSize: "13px",
    fontWeight: "400",
    color: "#0f2137",
    mb: 0,
    as: "span",
    ml: "10px"
  }
};

export default DomainSection;
