const colors = {
  transparent: "transparent",
  labelColor: "#767676",
  inactiveField: "#f2f2f2",
  inactiveButton: "#b7dbdd",
  inactiveIcon: "#EBEBEB",
  primaryHover: "#b11f28",
  secondary: "#ff5b60",
  secondaryHover: "#FF282F",
  yellow: "#fdb32a",
  yellowHover: "#F29E02",
  borderColor: "#dadada",
  black: "#000000",
  white: "#ffffff",
  primary: "#b11f28",
  headingColor: "#0f2137",
  quoteText: "#343d48",
  textColor: "rgba(52, 61, 72, 0.8)",
  linkColor: "#2b9eff"
};

export default colors;
