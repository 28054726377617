import React from "react";
import PropTypes from "prop-types";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Box from "reusecore/src/elements/Box";
import Card from "reusecore/src/elements/Card";
import Image from "reusecore/src/elements/Image";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/UI/Container";
import PartnerHistoryWrapper, { CounterUpArea } from "./partnerHistory.style";
import Creator01 from "../../../assets/image/app/tpp-creator01.jpg";
import Creator02 from "../../../assets/image/app/tpp-creator02.jpg";
import Creator03 from "../../../assets/image/app/tpp-creator03.jpg";
import Creator04 from "../../../assets/image/app/tpp-creator04.jpg";
import BackgroundImg from "../../../assets/image/app/partner-bg.png";

const PartnerHistory = ({
  row,
  col,
  cardStyle,
  title,
  description,
  btnStyle,
  sectionSubTitle,
  cardArea
}) => {
  return (
    <PartnerHistoryWrapper id="partners">
      <Image
        src={BackgroundImg}
        className="backgroungImg"
        alt="backgroungImg"
      />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col} style={{ flexDirection: "column" }}>
            <Text {...sectionSubTitle} />
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
              button={
              // <Button title="APPLY FOR EARLY ACCESS" {...btnStyle} />
                <AnchorLink href='#home'>
                  <a>
                    <Button title="APPLY FOR EARLY ACCESS" {...btnStyle} />
                  </a>
                </AnchorLink>
              }
            />
          </Box>
          <Box className="col" {...col} {...cardArea}>
            <CounterUpArea>
              <Card className="card" {...cardStyle}>
                <Image src={Creator01} alt="Featured Creator" />
                {/* <Text content="Google Inc" /> */}
              </Card>
              <Card className="card" {...cardStyle}>
                <Image src={Creator02} alt="Featured Creator" />
                {/* <Text content="Apple" /> */}
              </Card>
              <Card className="card" {...cardStyle}>
                <Image src={Creator03} alt="Featured Creator" />
                {/* <Text content="Dribble" /> */}
              </Card>
              <Card className="card" {...cardStyle}>
                <Image src={Creator04} alt="Featured Creator" />
                {/* <Text content="MailChimp" /> */}
              </Card>
            </CounterUpArea>
          </Box>
        </Box>
      </Container>
    </PartnerHistoryWrapper>
  );
};

// Partner style props
PartnerHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  cardStyle: PropTypes.object
};

// Partner default style
PartnerHistory.defaultProps = {
  // Partner section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px"
  },
  // Partner section col default style
  col: {
    pr: "15px",
    pl: "15px",
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: "center"
  },
  // Card default style
  cardStyle: {
    height: "200px",
    p: "0",
    borderRadius: "10px",
    boxShadow: "0px 8px 20px 0px rgba(16, 66, 97, 0.07)"
  },
  // Partner section title default style
  title: {
    content:
      "Here are some of the clients and companies our photographers have worked with:",
    fontSize: ["24px", "26px", "30px", "36px", "48px"],
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.010em",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "490px", "490px"],
    textAlign: ["center", "left"]
  },
  // Partner section description default style
  description: {
    content:
      "Manage your photography needs within TPP. Anything you need we can handle.",
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "2.1",
    mb: "33px",
    textAlign: ["center", "left"]
  },
  sectionSubTitle: {
    content: "OUR RAP SHEET",
    as: "span",
    textAlign: "left",
    fontSize: "14px",
    letterSpacing: "0.13em",
    fontWeight: "700",
    color: "#b11f28",
    mb: "10px",
    textAlign: ["center", "left"]
  },
  // Button default style
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500"
  },
  cardArea: {
    pl: [0, 0, "40px", 0, 0]
  }
};

export default PartnerHistory;
